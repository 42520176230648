import "./CalculateAge.scss";
import React, { useRef, useState } from "react";

const CalculateAge = () => {
    const inputRef = useRef();
    const [age, setAge] = useState();
    const [error, setError] = useState();

    const validateAge = () => {
        let value = inputRef.current.value.trim();
        setAge(false);
        switch (true) {
            case value < 1 || value === null || value === "":
                setError("Nhập tuổi của bạn đi đồ ngu!!!");
                break;
            case !isNumber(value):
                setError("Bạn nhập tuổi phải là số đồ ngu ạ!!!");
                break;
            case value < 1:
                setError("Bị ngu à!!");
                break;
            case value > 0 && value < 10:
                setError("Uống sữa đi cháu =)))");
                break;
            case value > 150:
                setError("Bạn sống thọ vậy á? Người nhiều tuổi nhất Việt Nam là 123 thôi =))");
                break;
            default:
                setError(false);
                setAge(value);
                break;
        }
    };
    const isNumber = (str) => {
        return !isNaN(str);
    };

    const handleSubmit = () => {
       alert('Không cần submit nhé bé!');
    };
    return (
        <div className="calculate">
            <div className="calculate-age">
                <div className="calculate-age__form">
                    <h2>Máy tính tuổi bậc nhất Việt Nam</h2>
                    <label htmlFor="age">Mời bạn nhập số tuổi của bạn</label>
                    <br />
                    <div className="form-group">
                        <input
                            className="form-control"
                            id="age"
                            ref={inputRef}
                            name="age"
                            type="text"
                            placeholder="Mời bạn nhập số tuổi ..."
                            onChange={() => validateAge()}
                        />
                        <button className="btn btn-primary btn-age" onClick={() => handleSubmit()}>
                            Submit
                        </button>
                    </div>
                </div>
                <div className="calculate-age__result">
                    {error && <p className="error">{error}</p>}
                    {age && <p>Tuổi của bạn là: {age}</p>}
                </div>
            </div>
        </div>
    );
};

export default CalculateAge;
