import config from '@config';
import Countdown from '@components/Countdown';
import Weather from '@components/Weather';
import BankAccount from '@pages/BankAccount';
import CalculateAge from '@pages/CalculateAge';
import GeneratePass from '@pages/GeneratePass';
import Home from '@pages/Home';
import QRcode from '@components/QRcode';
import IframePhaohoa from '@components/IframePhaohoa';
import IframeCodingUI from '@components/IframeCodingUI';

// Public routes
const publicRoutes = [
    { path: config.routes.home, component: Home },
    { path: config.routes.calculateAge, component: CalculateAge},
    { path: config.routes.passwordGenerator, component: GeneratePass},
    { path: config.routes.bankAccount, component: BankAccount},
    { path: config.routes.countdown, component: Countdown},
    { path: config.routes.weather, component: Weather},
    { path: config.routes.qrCode, component: QRcode},
    { path: config.routes.phaohoa, component: IframePhaohoa},
    { path: config.routes.codingui, component: IframeCodingUI},
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };