import "./BankList.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from "react";
import Modal from 'react-modal';

function BankList() {
    const [modalIsOpen, setIsOpen] = useState(false);
    const Banks = [
        {
            name: "Agribank",
            image:
                "https://upload.wikimedia.org/wikipedia/vi/thumb/3/3d/Argibank_logo.svg/1280px-Argibank_logo.svg.png",
            card: {
                name: "Phạm Quốc Chung",
                number: "0123456789",
                agency: "Lai Châu",
            },
        },
        {
            name: "Vietcombank",
            image: "",
            card: {
                name: "Phạm Quốc Chung 2",
                number: "0123456789",
                agency: "Hà Nội",
            },
        },
    ];

    const handleCopy = (item) => {
        let msg = `${item.name} ${item.card.agency} \n\rChủ tài khoản: ${item.card.name} \n\rSố tài khoản: ${item.card.number}`;
        navigator.clipboard?.writeText && navigator.clipboard.writeText(msg);
        toast.success('Copy to clipboard successfully');
    }
    const handlePopup = (item) => {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    Modal.setAppElement('#root');

    const BankItem = (items) => {
        return (
            <>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <button onClick={closeModal}>close</button>
                    <div className="modal__heading">
                        heading title
                    </div>
                    <div className="modal__body">
                        heading title
                    </div>


                </Modal>
                {items && items.props.map((item, index) => {
                    return (
                        <div className="item" key={index}>
                            <div className="item-image">
                                <img src={item.image} alt={item.name} />
                            </div>
                            <div className="item-info">
                                <ul>
                                    <li>
                                        <strong>{item.name}</strong>
                                    </li>
                                    <li>
                                        Chủ tài khoản:{" "}
                                        <strong>{item.card.name ?? item.card.name}</strong>
                                    </li>
                                    <li>
                                        Số tài khoản:{" "}
                                        <strong>{item.card.number ?? item.card.number}</strong>
                                    </li>
                                    <li>
                                        Chi nhánh:{" "}
                                        <strong>{item.card.agency ?? item.card.agency}</strong>
                                    </li>
                                </ul>
                            </div>
                            <div className="item-action">
                                <button className="btn btn-primary" onClick={() => handleCopy(item)}>Copy</button>
                                <button className="btn btn-primary" onClick={() => handlePopup(item)}>Tải QR</button>
                            </div>
                        </div>
                    );
                })
                }
            </>
        );
    };
    return (
        <div className="bank">
            <ToastContainer />
            <div className="bank-list">
                <BankItem props={Banks} />
            </div>
        </div>

    );
}

export default BankList;
