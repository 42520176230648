import React from "react";
import background from '@assets/images/services/sap-tet-roi.jpg';
import './Clock.scss'
import ReactAudioPlayer from "react-audio-player";
import url from '@assets/mp3/tetdenroi.mp3';
const Clock = ({ timerDays, timerHours, timerMinutes, timerSeconds }) => {
  return (
    <>
      <section className="section-countdown" style={{ backgroundImage: `url(${background})` }}>
        <ReactAudioPlayer
          src={url}
          autoPlay
          controls
        />
        <div className="section-countdown__wrapper">
          <h1>Sắp Tết rồi!!! Chỉ còn...</h1>
          <div className="timer">
            <div className="clock">
              <span className="number">{timerDays}</span>
              <span className="text">Days</span>
            </div>
            <div className="clock">
              <span className="number">{timerHours}</span>
              <span className="text">Hours</span>
            </div>
            <div className="clock">
              <span className="number">{timerMinutes}</span>
              <span className="text">Minutes</span>
            </div>
            <div className="clock">
              <span className="number">{timerSeconds}</span>
              <span className="text">Seconds</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Clock;