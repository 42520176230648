import './DefaultLayout.scss';
import Header from "@components/Share/Header";
import ColumnLeft from "@components/Share/Sidebar";
import bg from "@assets/images/background/bg.jpg";
function DefaultLayout({ children }) {
  const styleInline = {
    backgroundImage: `url(${bg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div className="default-layout" style={styleInline}>
      <ColumnLeft />
      <div id="content" className="content">
        <Header />
        {children}
      </div>
    </div>
  );
}

export default DefaultLayout;
