import { useEffect, useState } from "react";
import WeatherDetails from "./WeatherDetails";
function Weather() {
    const [weather, setWeather] = useState(null);
    useEffect(() => {
        loadInfo();
    }, []);
    useEffect(() => {
        document.title = `Weather | ${weather?.location.country}`;
    }, [weather]);

    async function loadInfo(city = "Lai Chau") {
        try {
            const data = await fetch(
                `https://api.weatherapi.com/v1/current.json?aqi=no&key=e3c55ad24d104b8c940215558220406&lang=vi&q=${city}`
            );
            const response = await data.json();
            setWeather(response);
        } catch (error) { 
            console.log(error);
        }
    }
    return (
        <>
            {weather ? (
                <div className="weather">
                    <WeatherDetails weather={weather} />
                </div>
            ) : (
                <>Loading</>
            )}
        </>
    );
}

export default Weather;