import { useState, useEffect } from 'react';
import './FormGeneratePass.scss';

function FormGeneratePass() {
  const [password, setPassword] = useState('')
  const [firstCharacter, setFirstCharacter] = useState('')
  const [passwordLength, setPasswordLength] = useState(15)
  const [uppercase, setUppercase] = useState(true)
  const [lowercase, setLowercase] = useState(true)
  const [numbers, setNumbers] = useState(true)
  const [symbols, setSymbols] = useState(true)
  const [errors, setErrors] = useState({})

  const generatePassword = () => {
    setErrors({})
    if (!uppercase && !lowercase && !numbers && !symbols) {
      return setErrors('At least one character type must be selected')
    } else if (passwordLength === '0') {
      return setErrors('Password length cannot be 0')
    } else if (passwordLength === '') {
      return setErrors('Invalid password length')
    } else if (passwordLength > 80) {
      return setErrors('Password length cannot exceed 80 characters')
    }else if (firstCharacter.length >= passwordLength) {
      return setErrors('First Character length cannot >= Password Length')
    }

    let password = ''
    let _passwordLength = passwordLength;
    if(firstCharacter.length>0){
      password += firstCharacter;
      _passwordLength = passwordLength - firstCharacter.length;
    }
    for (let i = 0; i < _passwordLength; i++) {
      let choice = random(0, 3)
      if (lowercase && choice === 0) {
        password += randomLower()
      } else if (uppercase && choice === 1) {
        password += randomUpper()
      } else if (symbols && choice === 2) {
        password += randomSymbol()
      } else if (numbers && choice === 3) {
        password += random(0, 9)
      } else {
        i--
      }
    }
    setPassword(password)
  }

  const random = (min = 0, max = 1) => {
    return Math.floor(Math.random() * (max + 1 - min) + min)
  }

  const randomLower = () => {
    return String.fromCharCode(random(97, 122))
  }

  const randomUpper = () => {
    return String.fromCharCode(random(65, 90))
  }

  const randomSymbol = () => {
    const symbols = "~*$%@#^&!?*'-=/,.{}()[]<>"
    return symbols[random(0, symbols.length - 1)]
  }

  useEffect(() => {
    generatePassword()
    // eslint-disable-next-line
  }, [])

  const handleCopy = (e) => {
    var textField = document.createElement('textarea')
    textField.innerText = password
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    alert('Password copied to clipboard');
  }

  return (
    <div className="genarate">
      <div className="genarate-pass">
        <div className="genarate-pass__header">
          <div className="genarate-pass__header__result">
            <span id="result">{password}</span>
            <span id="copy" onClick={() => handleCopy()}>Copy</span>
          </div>
        </div>
        <div className="genarate-pass__body">
          <div className="form-control">
            <label htmlFor="length">Nhập độ dài mật khẩu</label>
            <input
              id='length'
              type='number'
              name='length'
              min='4'
              max='50'
              defaultValue={passwordLength}
              onChange={(e) => setPasswordLength(e.target.value)}
            />
          </div>
          <div className="form-control">
            <label htmlFor="firstCharacter">Ký tự đầu mật khẩu</label>
            <input
              id='firstCharacter'
              type='text'
              name='firstCharacter'
              defaultChecked={firstCharacter}
              onChange={(e) => setFirstCharacter(e.target.value)}
            />
          </div>
          <div className="form-control">
            <label htmlFor="uppercase">Chứa ký tự viết hoa</label>
            <input
              id='uppercase'
              type='checkbox'
              name='uppercase'
              defaultChecked={uppercase}
              onChange={(e) => setUppercase(e.target.checked)}
            />
          </div>
          <div className="form-control">
            <label htmlFor="lowercase">Chứa ký tự viết thường</label>
            <input
              id='lowercase'
              type='checkbox'
              name='lowercase'
              defaultChecked={lowercase}
              onChange={(e) => setLowercase(e.target.checked)}
            />
          </div>
          <div className="form-control">
            <label htmlFor="numbers">Chứa số</label>
            <input
              id='numbers'
              type='checkbox'
              name='numbers'
              defaultChecked={numbers}
              onChange={(e) => setNumbers(e.target.checked)}
            />
          </div>
          <div className="form-control">
            <label htmlFor="symbols">Chứa ký tự đặc biệt</label>
            <input
              id='symbols'
              type='checkbox'
              name='symbols'
              defaultChecked={symbols}
              onChange={(e) => setSymbols(e.target.checked)}
            />
          </div>

          <button className="btn btn-block btn-light" onClick={() => generatePassword()}>Tạo mật khẩu</button>
          {errors.length && <span className='error'>{errors}</span>}
        </div>
      </div>
    </div>
  );
}

export default FormGeneratePass;