function Iframe(props) {
    const { title = 'Iframe Customize', style='', className = 'iframe__customize', ...rest } = props;
    const myStyle = {
        width: "100%",
        height: "calc(100vh - 50px)",
    }
    return ( 
        <iframe {...rest} style={style || myStyle} title={title} className={className} ></iframe>
     );
}

export default Iframe;