import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Header.scss";
function Header() {
    const handleClick = () =>{
        let sidebar = document.getElementById('sidebar');
        sidebar.classList.toggle('open');
    }
    return (
        <div id="header" className="header">
            <div className="header__button" onClick={handleClick}>
                <FontAwesomeIcon icon={faBars} />
            </div>
        </div>
    );
}

export default Header;
