import BankList from "@components/BankList";
import { useEffect } from "react";

function BankAccount() {
    useEffect(() => {
        document.title = 'Bank Account';
    }, [])
    return (  
        <>
        <BankList />
        </>
    );
}

export default BankAccount;