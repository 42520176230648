import { useQRCode } from "next-qrcode";
import { useEffect } from "react";
import QRForm from "./QRForm";
function QRcode() {
    const { Canvas, SVG } = useQRCode();
    useEffect(() => {
        document.title = `QR Code`;
    }, []);
    return (
        <div className="qr-code">
            <QRForm />
            <Canvas
                text={"https://chungpq.click"}
                options={{
                    level: "M",
                    margin: 3,
                    scale: 4,
                    width: 200,
                    color: {
                        dark: "#010599FF",
                        light: "#FFBF60FF",
                    },
                }}
            />
             <SVG
                text={'https://github.com/bunlong/next-qrcode'}
                logo={'https://zozo.vn/public/resources/license/logo.png'}
                options={{
                    level: 'M',
                    margin: 3,
                    scale: 4,
                    width: 200,
                    color: {
                    dark: '#000000ff',
                    light: '#ffffffff',
                    },
                }}
                />
        </div>
    );
}

export default QRcode;
