const routes = {
    home: '/',
    calculateAge: '/tinh-tuoi',
    passwordGenerator: '/password-generator',
    bankAccount: '/bank',
    countdown: '/dem-ngay-den-tet',
    weather: '/weather',
    qrCode: '/qr-code',
    phaohoa: '/phao-hoa',
    codingui: '/codingui',
};

export default routes;
