import { useEffect } from 'react';
import CalculateAgeCom from '@components/CalculateAge';
function CalculateAge() {
    useEffect(() => {
        document.title = 'Calculate Age';
    }, [])
    return ( 
        <>
        <CalculateAgeCom />
        </>
     );
}

export default CalculateAge;