import React, { useState, useEffect } from "react";
import Clock from "./Clock";

function Countdown() {
    const [timerDays, setTimerDays] = useState(0);
    const [timerHours, setTimerHours] = useState(0);
    const [timerMinutes, setTimerMinutes] = useState(0);
    const [timerSeconds, setTimerSeconds] = useState(0);
    useEffect(() => {
      document.title = "Countdown";
    }, []);
    let interval;
    const startTimer = () => {
      const dateCountdown = new Date("01-22-2023").getTime();
    
      interval = setInterval(() => {
        const dateNow = new Date().getTime();
  
        const distance = dateCountdown - dateNow;

        const days = Math.floor(distance / (24 * 60 * 60 * 1000));
        const hours = Math.floor(
          (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
        const seconds = Math.floor((distance % (60 * 1000)) / 1000);
  
        if (distance < 0) {
          clearInterval(interval.current);
        } else {
          setTimerDays(days);
          setTimerHours(hours);
          setTimerMinutes(minutes);
          setTimerSeconds(seconds);
        }
      });
    };
    useEffect(() => {
      startTimer();
    });
    return (
      <>
        <Clock
          timerDays={timerDays}
          timerHours={timerHours}
          timerMinutes={timerMinutes}
          timerSeconds={timerSeconds}
        />
      </>
    );
}

export default Countdown;