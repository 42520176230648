import Iframe from "@components/Iframe";
import { useEffect } from "react";

function IframePhaohoa({props}) {
    useEffect(() => {
        document.title = 'Pháo hoa - Mr Chung Developer';
    }, [])
   
    return ( 
        <div className="iframe__phaohoa">
            <Iframe src="https://phaohoa.chungpq.click" title="ChungPQ - Pháo Hoa"></Iframe>
        </div>
     );
}

export default IframePhaohoa;