import "./Sidebar.scss";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud, faCode, faFire, faHourglass, faHouseUser, faIdCard, faInfo, faKey, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { faCircleQuestion, faMessage } from "@fortawesome/free-regular-svg-icons";
import logo from "@assets/images/logo/logo2.png";
function Sidebar() {
    const [isActive, setIsActive] = useState(1);

    const handleActive = (id) => {
        setIsActive(id);
    };
    const menus = [
        {
            id: 1,
            name: "Trang chủ",
            icon:<FontAwesomeIcon icon={faHouseUser}></FontAwesomeIcon>,
            link: "/",
        },
        {
            id: 8,
            name: "Pháo hoa",
            icon: <FontAwesomeIcon icon={faFire}></FontAwesomeIcon>,
            link: "/phao-hoa",
        },
        {
            id: 9,
            name: "Coding UI",
            icon: <FontAwesomeIcon icon={faCode}></FontAwesomeIcon>,
            link: "/codingui",
        },
        {
            id: 2,
            name: "Tính tuổi của bạn",
            icon: <FontAwesomeIcon icon={faMessage}></FontAwesomeIcon>,
            link: "/tinh-tuoi",
        },
        {
            id: 3,
            name: "Tự động tạo Password",
            icon: <FontAwesomeIcon icon={faKey}></FontAwesomeIcon>,
            link: "/password-generator",
        },
        {
            id: 4,
            name: "Tài khoản cá nhân",
            icon: <FontAwesomeIcon icon={faInfo}></FontAwesomeIcon>,
            link: "/bank",
        },
        {
            id: 5,
            name: "Countdown",
            icon: <FontAwesomeIcon icon={faHourglass}></FontAwesomeIcon>,
            link: "/dem-ngay-den-tet",
        },
        {
            id: 6,
            name: "Weather",
            icon: <FontAwesomeIcon icon={faCloud}></FontAwesomeIcon>,
            link: "/weather",
        },
        {
            id: 7,
            name: "QR Code",
            icon: <FontAwesomeIcon icon={faQrcode}></FontAwesomeIcon>,
            link: "/qr-code",
        },
    ];
    return (
        <div id="sidebar" className="sidebar">
            <div className="sidebar__logo">
                <img src={logo} alt="Manager Tools" />
                <h3 className="name">Manager Tools</h3>
            </div>
            <nav className="sidebar__nav">
                <div className="nav-title">Management</div>
                <ul className="nav-menu">
                    {menus &&
                        menus.map((item, index) => {
                            console.log(typeof item.icon);
                            return (
                                <li
                                    className={
                                        "nav-menu__item " + (isActive === item.id && "active")
                                    }
                                    onClick={() => handleActive(item.id)}
                                    key={index}
                                >
                                    <Link to={item.link}>
                                        {item.icon}
                                        <span>{item.name}</span>
                                    </Link>
                                </li>
                            );
                        })}
                </ul>
                <hr />
                <div className="nav-title">Supports</div>
                <ul className="nav-menu">
                    <li className="nav-menu__item">
                        <a href="https://chungpq.click" title="Get Help" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faIdCard} />
                            <span>Portfolio</span>
                        </a>
                    </li>
                    <li className="nav-menu__item">
                        <a href="https://fb.com/chungpq.2000" title="Get Help" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faCircleQuestion} />
                            <span>Get Help</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default Sidebar;
