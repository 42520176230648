import FormGeneratePass from "@components/FormGeneratePass";
import { useEffect } from 'react';

function GeneratePass() {
    useEffect(() => {
        document.title = 'Generate Password';
    }, [])
    return ( 
        <>
        <FormGeneratePass />
        </>
     );
}

export default GeneratePass;