import './WeatherDetails.scss';
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
function WeatherDetails({ weather }) {
    return (
        <div className="weather__wrapper">
            <div className="weather__wrapper__top">
                <div>
                    <h1 className="weather-title">Today</h1>
                    <p className="paragraph">
                        {moment(weather?.location.localtime).format("h:mm a")}
                    </p>
                </div>
                <p className="paragraph">
                    {moment(weather?.location.localtime).format("MMM Do YY")}
                </p>
            </div>
            <div className="weather__wrapper__middle">
                <span className="weather-temp-main">
                    {weather?.current.temp_c} <span className="weather-tepm">°C</span>
                </span>
                <img
                    width="220"
                    height="220"
                    src={weather?.current.condition.icon}
                    alt={`Icon of ${weather?.location.country}`}
                />
            </div>
            <div className="weather__wrapper__bottom">
                <FontAwesomeIcon icon={faLocationDot}/>
                <p className="paragraph">
                    {weather?.location.country}, {weather?.location.name}
                </p>
            </div>
        </div>
    );
}

export default WeatherDetails;
