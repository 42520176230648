function QRForm() {
    return ( <>
    
    <div className="qr__form">
        <div className="form-group">
            <label htmlFor="type">Render as:</label>
            <select id="type" className="form-control">
                <option>Canvas</option>
                <option>Image</option>
                <option>Svg</option>
            </select>
        </div>
        <div className="form-group">
            <label>Text:</label>
            <input type="text" name="text" className="form-control" />
        </div>
        <div className="form-group">
            <label htmlFor="option">Include Options:</label>
            <input type="checkbox" id="option" name="option"/>
        </div>
        <fieldset>
        <legend>Options</legend>
        </fieldset>
    </div>


    </> );
}

export default QRForm;